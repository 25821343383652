import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ArriowRightCircle, ArrowRightCircle } from "react-bootstrap-icons";
import TrackVisibility from "react-on-screen";
import "animate.css";
import headerImg from "../assets/img/freepik-export-20240716050128KJLc.png";
import { isVisible } from "@testing-library/user-event/dist/utils";
import bannerCoder from "../assets/img/banner-icon.png";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const professionList = [
    "Frontend Developer",
    "Backend Developer",
    "Fullstack Developer",
  ];
  const [text, setText] = useState("");
  const [index, setIndex] = useState(1);
  const [delta, setDelta] = useState(1);
  const period = 3000; //Amount of time between each word transition

  //ticker function set to call the tick function every delta milliseconds
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  const tick = () => {
    let i = loopNum % professionList.length;
    let fullText = professionList[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    let threeSecondCD = 3000;

    setText(updatedText);

    if (isDeleting) {
      setDelta(50);
    }

    if (!isDeleting && updatedText == fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(3000); //Delay next tick in milliseconds
    } else if (isDeleting && updatedText == "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(50);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  function goToAnchor(anchor) {
    var loc = document.location.toString().split("#")[0];
    document.location = loc + "#" + anchor;
    return false;
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={12} md={12} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : " "
                  }
                >
                  <span className="tagline">Welcome to my portfolio</span>

                  <Col className="name-job-title">
                    <h1
                      className={
                        viewportWidth > 500 ? "display-4" : "display-4 my-name"
                      }
                    >
                      Lee Kai Sheng (Larry)
                    </h1>
                    <br></br>
                    <h2
                      className={
                        viewportWidth > 750
                          ? "display-4"
                          : "display-4 moving-text"
                      }
                    >
                      {text}&nbsp;
                    </h2>

                    <p>
                      I am a web developer with nearly two years of experience.
                      With a keen eye for detail and a commitment to innovation,
                      I specialize in leveraging emerging tools to create robust
                      solutions that propel businesses forward.
                    </p>
                    <button onClick={() => goToAnchor("connect")}>
                      Lets Connect
                      <ArrowRightCircle size={25}></ArrowRightCircle>
                    </button>
                  </Col>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} sm={12} md={12} xl={5}>
            <img src={bannerCoder} alt="Header Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
