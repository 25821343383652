import { useState } from "react";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";

export const Contact = () => {
  const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";
  const CONTACT_US_URL = `${API_URL}/contact`;

  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };

  const cors = require("cors");

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});

  //Function Declaration
  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents page from getting reloaded
    setButtonText("Sending...");
    console.log("Sending");

    let response = await fetch(CONTACT_US_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDetails),
    });

    setButtonText("Send");

    let result;
    try {
      result = await response.json();
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setStatus({
        succes: false,
        message: "Error parsing response. Please try again later.",
      });
      return;
    }

    if (result.code === 200) {
      setStatus({ succes: true, message: "Message sent successfully" });
    } else {
      setStatus({
        succes: false,
        message: "Something went wrong, please try again later.",
      });
    }

    setFormDetails(formInitialDetails);
  };

  function handleContactMeStatusMsg(status) {
    if (!status.message) {
      return null;
    }

    const className =
      status.success === false
        ? "danger response-text"
        : "success response-text";

    return <div className={className}>{status.message}</div>;
  }

  return (
    <section className="contact" id="connect">
      <Container>
        <p>
          <h2 className="section-title">Get In Touch</h2>
        </p>

        <Row className="align-items-center">
          <Col md={6}>
            <img src={contactImg} alt="Contact Me" />
          </Col>
          <Col md={6}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col sm={6} className="px-1">
                  <input
                    type="text"
                    value={formDetails.firstName}
                    placeholder="First Name"
                    onChange={(e) => onFormUpdate("firstName", e.target.value)}
                    required
                  />
                </Col>

                <Col size={12} sm={6} className="px-1">
                  <input
                    type="text"
                    value={formDetails.lastName}
                    placeholder="Last Name"
                    onChange={(e) => onFormUpdate("lastName", e.target.value)}
                    required
                  />
                </Col>
                <Col size={12} sm={6} className="px-1">
                  <input
                    type="email"
                    value={formDetails.email}
                    placeholder="Email"
                    onChange={(e) => onFormUpdate("email", e.target.value)}
                    required
                  />
                </Col>
                <Col size={12} sm={6} className="px-1">
                  <input
                    type="phone"
                    value={formDetails.phone}
                    placeholder="Phone Number"
                    onChange={(e) => onFormUpdate("phone", e.target.value)}
                    required
                  />
                </Col>
                <Col size={12} className="px-1">
                  <textarea
                    rows={6}
                    value={formDetails.message}
                    placeholder="Message"
                    onChange={(e) => onFormUpdate("message", e.target.value)}
                    required
                  />
                </Col>
                <Row>
                  <Col>{handleContactMeStatusMsg(status)}</Col>
                  <Col>
                    <button rows={6} type="submit">
                      <span>{buttonText}</span>
                    </button>
                  </Col>
                </Row>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
