import { Helmet } from "react-helmet";
export const HelmetSEO = () => {
  return (
    <Helmet>
      <title>Lee Kai Sheng Larry - Web Developer Portfolio</title>
      <meta
        name="keywords"
        content="Lee Kai Sheng Larry, web developer, portfolio, web design, programming, JavaScript, React, HTML, CSS"
      />
      <meta
        name="description"
        content="Welcome to my web portfolio! No need to knock—come on in, take a look around, and don't worry, 
                I promise I don’t bite. From front-end finesse to back-end wizardry, I've got a passion for all things tech!"
      />

      <meta
        property="og:title"
        content="Lee Kai Sheng Larry - Web Developer Portfolio"
      />
      <meta
        property="og:description"
        content="Welcome to my web portfolio! No need to knock—come on in, take a look around, and don't worry, 
                I promise I don’t bite. From front-end finesse to back-end wizardry, I've got a passion for all things tech!"
      />

      <meta property="og:url" content={process.env.WEB_URL} />
    </Helmet>
  );
};
