import { Navbar, Nav, Container } from "react-bootstrap";
import { useState, useEffect } from "react";
//Importing my images
import logo from "../assets/img/boy-pompadour.png";
import NavIcon1 from "../assets/img/nav-icon1.svg";
import NavIcon2 from "../assets/img/nav-icon2.svg";
import NavIcon3 from "../assets/img/nav-icon3.svg";
import githubIcon from "../assets/img/github-icon.svg";
import downloadResumeIcon from "../assets/img/download-window-svgrepo-com.svg";
import downloadResumeIcon2 from "../assets/img/ResumeDownload.svg";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const [navBarTextColor, setNavBarTextColor] = useState("");

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/">
          <h1
            className={
              scrolled
                ? activeLink === "skills"
                  ? "active navbar-link scrolled"
                  : "navbar-link scrolled"
                : activeLink === "skills"
                ? "active navbar-link "
                : "navbar-link "
            }
            style={{ color: "#262727" }}
          >
            LKS
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span
            className={
              scrolled ? "navbar-toggler-icon scrolled" : "navbar-toggler-icon"
            }
          ></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              href="#home"
              className={
                scrolled
                  ? activeLink === "home"
                    ? "active navbar-link scrolled"
                    : "navbar-link scrolled"
                  : activeLink === "home"
                  ? "active navbar-link "
                  : "navbar-link "
              }
              onClick={() => onUpdateActiveLink("home")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#skills"
              className={
                scrolled
                  ? activeLink === "skills"
                    ? "active navbar-link scrolled"
                    : "navbar-link scrolled"
                  : activeLink === "skills"
                  ? "active navbar-link "
                  : "navbar-link "
              }
              onClick={() => onUpdateActiveLink("skills")}
            >
              Skills
            </Nav.Link>

            <Nav.Link
              href="#experiences"
              className={
                scrolled
                  ? activeLink === "experiences"
                    ? "active navbar-link scrolled"
                    : "navbar-link scrolled"
                  : activeLink === "experiences"
                  ? "active navbar-link "
                  : "navbar-link "
              }
              onClick={() => onUpdateActiveLink("experiences")}
            >
              Experience
            </Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href={process.env.REACT_APP_LINKEDIN_URL}>
                <img src={NavIcon1} alt="" />
              </a>

              <a href={process.env.REACT_APP_RESUME_URL}>
                <img src={downloadResumeIcon2} alt="" />
              </a>
            </div>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
