import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";
import angular from "../assets/img/WebTechIcons/angular.svg";
import bootstrap from "../assets/img/WebTechIcons/bootstrap.svg";
import css3 from "../assets/img/WebTechIcons/css3.svg";
import html from "../assets/img/WebTechIcons/html5.svg";
import java from "../assets/img/WebTechIcons/java.svg";
import javascript from "../assets/img/WebTechIcons/javascript.svg";
import jira from "../assets/img/WebTechIcons/jira.svg";
import mySql from "../assets/img/WebTechIcons/mysql.svg";
import python from "../assets/img/WebTechIcons/python.svg";
import react from "../assets/img/WebTechIcons/react.svg";
import springboot from "../assets/img/WebTechIcons/springboot.svg";
import typescript from "../assets/img/WebTechIcons/typescript.svg";
import slack from "../assets/img/WebTechIcons/slack.svg";
import unity from "../assets/img/WebTechIcons/Unity.svg";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2 className="display-1 section-title">Skills</h2>
              <p>
                These are all the skills that I have picked up in my current
                journey to becoming better.
              </p>
              <Row>
                <Col xs={12} s={12} md={12} xl={4}>
                  <Row>
                    <h3 className="display-4 skills-subtitle">Languages</h3>
                  </Row>
                  <Row>
                    <Col className="web-skills programming-languages">
                      <img className="icon-size" src={java} alt="Image" />
                      <div className="skills-icon-text">Java</div>
                    </Col>
                    <Col className="web-skills programming-languages">
                      <img className="icon-size" src={javascript} alt="Image" />
                      <div className="skills-icon-text">Javascript</div>
                    </Col>
                    <Col className="web-skills programming-languages">
                      <img className="icon-size" src={typescript} alt="Image" />
                      <div className="skills-icon-text">Typescript</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="web-skills programming-languages">
                      <img className="icon-size" src={mySql} alt="Image" />
                      <div className="skills-icon-text">mysql</div>
                    </Col>
                    <Col className="web-skills programming-languages">
                      <img className="icon-size" src={css3} alt="Image" />
                      <div className="skills-icon-text">CSS3</div>
                    </Col>
                    <Col className="web-skills programming-languages">
                      <img className="icon-size" src={html} alt="Image" />
                      <div className="skills-icon-text">HTML5</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="web-skills programming-languages">
                      <img className="icon-size" src={python} alt="Image" />
                      <div className="skills-icon-text">Python</div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} s={12} md={12} xl={4}>
                  <Row>
                    <h3 className="display-4 skills-subtitle">Technologies</h3>
                  </Row>
                  <Row>
                    <Col className="web-skills programming-languages">
                      <img className="icon-size" src={react} alt="Image" />
                      <div className="skills-icon-text">React</div>
                    </Col>
                    <Col className="web-skills web-technologies">
                      <img className="icon-size" src={angular} alt="Image" />
                      <div className="skills-icon-text">Angular</div>
                    </Col>
                    <Col className="web-skills web-technologies">
                      <img className="icon-size" src={bootstrap} alt="Image" />
                      <div className="skills-icon-text">Bootstrap</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="web-skills web-technologies">
                      <img className="icon-size" src={springboot} alt="Image" />
                      <div className="skills-icon-text">Spring Boot</div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} s={12} md={12} xl={4}>
                  <Row>
                    <h3 className="display-4 skills-subtitle">Others</h3>
                  </Row>
                  <Row>
                    <Col className="web-skills others">
                      <img className="icon-size" src={slack} alt="Image" />
                      <div className="skills-icon-text">Slack</div>
                    </Col>
                    <Col className="web-skills others">
                      <img className="icon-size" src={jira} alt="Image" />
                      <div className="skills-icon-text">Jira</div>
                    </Col>
                    <Col className="web-skills others">
                      <img className="icon-size" src={unity} alt="Image" />
                      <div className="skills-icon-text">Unity3D</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-left" src={colorSharp}></img>
    </section>
  );
};
