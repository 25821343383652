import { Col, Container, Row } from "react-bootstrap";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import navIcon1 from "../assets/img/github-icon.svg";

export const Experience = () => {
  const burgundy = "#800020";
  const smokeWhite = "#f0f0f0";
  const sageGreen = "#808e76";
  const coolOrange = "#f1ad45";
  const onyxBlack = "#262727";
  const bgCream = "#fbf3e3";

  return (
    <section className="experience" id="experiences">
      <Container>
        <h2 className="display-1 section-title">Experiences</h2>
        <p>A comprehensive timeline of my professional work life</p>
        <Row>
          <Col>
            <VerticalTimeline lineColor={"#808e76"}>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: sageGreen,
                  border: `2px solid ${onyxBlack}`,
                }}
                contentArrowStyle={{
                  borderRight: `20px solid  ${sageGreen}`,
                }}
                date="Apr 2023 - Feb 2024"
                iconStyle={{
                  background: sageGreen,
                  border: "5px solid",
                }}
                icon={<navIcon1 />}
              >
                <div className="technologiesBar">
                  <span className="technology">Spring Boot</span>
                  <span className="technology">React</span>
                </div>
                <h3 className="vertical-timeline-element-title">
                  Full Stack Web Developer
                </h3>
                <h5 className="vertical-timeline-element-subtitle">
                  Geoconsult Asia Singapore Pte Ltd
                </h5>
                <span className="deep-green-text">
                  <ul>
                    <li>
                      Strategize and execute solutions for migrating in-house
                      web applications.
                    </li>
                    <li>
                      Facilitate support for mission-critical in-house web
                      applications.
                    </li>
                    <li>Deliver essential IT support services.</li>
                  </ul>
                </span>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: sageGreen,
                  border: `2px solid ${onyxBlack}`,
                }}
                date="Mar 2022 - Apr 2023"
                iconStyle={{
                  background: sageGreen,
                  border: `5px solid ${onyxBlack}`,
                }}
                icon={<navIcon1 />}
                contentArrowStyle={{
                  borderRight: `20px solid  ${sageGreen}`,
                }}
              >
                <div className="technologiesBar">
                  <span className="technology">Spring Boot</span>
                  <span className="technology">React</span>
                </div>
                <h3 className="vertical-timeline-element-title">
                  Java Developer
                </h3>
                <h5 className="vertical-timeline-element-subtitle">
                  DXC Technology
                </h5>
                <span className="deep-green-text">
                  <ul>
                    <li>
                      Design and develop solutions to address end-user requests
                      within an Agile environment.
                    </li>
                    <li>
                      Ensure high customer satisfaction through mission-critical
                      support services.
                    </li>
                    <li>
                      Utilize technical stacks including Spring (Java), MySQL,
                      Hibernate, and Struts.
                    </li>
                  </ul>
                </span>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: sageGreen,
                  border: `2px solid ${onyxBlack}`,
                }}
                date="Aug 2021 - Nov 2021"
                iconStyle={{
                  background: sageGreen,
                  border: `5px solid ${onyxBlack}`,
                }}
                icon={<navIcon1 />}
                contentArrowStyle={{
                  borderRight: `20px solid  ${sageGreen}`,
                }}
              >
                <div className="technologiesBar">
                  <span className="technology">Unity3D</span>
                  <span className="technology">Slack</span>
                </div>
                <h3 className="vertical-timeline-element-title">
                  Software Developer (Intern)
                </h3>
                <h5 className="vertical-timeline-element-subtitle">
                  Singapore Institute of Technology
                </h5>
                <span className="deep-green-text">
                  <ul>
                    <li>
                      Designed and implemented interaction scripting to
                      facilitate seamless user-object interactions within the
                      simulation.
                    </li>
                    <li>
                      Conducted extensive research to assess the viability of
                      procedural generation techniques for enhancing automation
                      capabilities within the simulation environment.
                    </li>
                  </ul>
                </span>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: sageGreen,
                  border: `2px solid ${onyxBlack}`,
                }}
                date="May 2021 - July 2021"
                iconStyle={{
                  background: sageGreen,
                  border: `5px solid ${onyxBlack}`,
                }}
                icon={<navIcon1 />}
                contentArrowStyle={{
                  borderRight: `20px solid  ${sageGreen}`,
                }}
              >
                <div className="technologiesBar">
                  <span className="technology">Cocos2D</span>
                  <span className="technology">Slack</span>
                </div>
                <h3 className="vertical-timeline-element-title">
                  Frontend Developer (Intern)
                </h3>
                <h5 className="vertical-timeline-element-subtitle">
                  Dynamite Games
                </h5>
                <span className="deep-green-text">
                  <ul>
                    <li>
                      Analyzing and enhancing core gameplay mechanics based on
                      project requirements.
                    </li>
                    <li>
                      Identifying and resolving issues through comprehensive
                      testing and debugging.
                    </li>
                  </ul>
                </span>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
